<template>
  <!-- skin-white -->
  <div
    class="auHeader"
    :class="{
      hide: type == 'top' && top > 0,
      'skin-default': skinDefault,
      'skin-white': top > 0,
    }"
  >
    <el-row
      class="auPageCenter"
      type="flex"
      align="middle"
      justify="space-between"
    >
      <!-- logo -->
      <a href="/" class="left">
        <img class="logo" src="@/assets/logo.png" />
        <img class="logo2" src="@/assets/logo2.png" />
      </a>
      <el-row>
        <!-- 菜单导航 -->
        <el-menu
          class="el-menu-demo"
          :default-active="defaultActive"
          mode="horizontal"
          router
          @select="handleSelect"
        >
          <el-menu-item index="/">首页</el-menu-item>
          <el-menu-item index="/extension">插件下载</el-menu-item>
          <el-menu-item index="/about">关于我们</el-menu-item>
        </el-menu>
        <!-- 登录 -->
        <div class="center login">
          <span @click="$router.push('/login')">登录</span>
          <span>/</span>
          <span @click="$router.push('/register')">注册</span>
        </div>
      </el-row>
    </el-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      defaultActive: "",
      routerViews: ["/", "/about","/login","/register"],
      skinDefault: false,
    };
  },
  computed: {
    ...mapState({
      top: (state) => state.web.top,
      type: (state) => state.web.scollType,
    }),
  },
  watch: {
    $route(to, from) {
      this.updateSkin();
    },
  },
  methods: {
    updateSkin() {
      this.defaultActive = this.$route.path;
      this.skinDefault = !this.routerViews.includes(this.defaultActive);
    },
  },
  created() {
    //onScroll
    window.auHeader = this;
    this.$store.dispatch("onScroll");
    this.updateSkin();
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.auHeader {
  min-width: var(--au-width);
  width: 100%;
  height: 60px;
  background: rgba(255, 255, 255, 0);
  border-bottom: 1px solid rgba(248, 250, 255, 0.1);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  transition: all 0.3s;
  .logo,
  .logo2 {
    height: 30px;
  }
  .logo {
    display: none;
  }
  .title {
    font-size: 24px;
    font-weight: bold;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .left {
    align-items: center;
    display: flex;
    & > * {
      margin-right: 8px;
    }
  }
  .login {
    margin-left: 6px;
    color: #fff;
    padding-bottom: 2px;
    box-sizing: border-box;
    span{
      margin: 0 2px;
      cursor: pointer;
    }
  }

  ::v-deep {
    .el-menu {
      background-color: rgba(255, 255, 255, 0);
    }
    .el-menu-item {
      color: #fff;
      position: relative;
      background-color: rgba(255, 255, 255, 0);
      &:hover,
      &:focus {
        color: #fff;
        background-color: rgba(255, 255, 255, 0);
      }
      &.is-active {
        border-bottom: none !important;
        color: #fff;
        &::after {
          content: "";
          position: absolute;
          width: 30%;
          height: 3px;
          bottom: -1px;
          left: 50%;
          transform: translateX(-50%);
          border-radius: 100px;
          background-color: #fff;
        }
      }
    }
    .el-menu.el-menu--horizontal {
      border-bottom: none;
    }
  }
  $color: #3c4254;
  &.skin-white {
    background: rgb(255, 255, 255);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    .logo2 {
      display: none;
    }
    .logo {
      display: block;
    }
    .login{
      color:$color;
    }
    ::v-deep {
      .el-menu-item {
        color: $color;
        &:hover,
        &:focus {
          color: $color;
        }
        &.is-active {
          color: $color;
          &::after {
            background-color: var(--au-color);
          }
        }
      }
    }
  }
  &.hide {
    top: -63px;
  }
  &.skin-default {
    border-bottom: 1px solid #e8ebf0;
    .logo2 {
      display: none;
    }
    .logo {
      display: block;
    }
    .login{
      color:$color;
    }
    ::v-deep {
      .el-menu-item {
        color: $color;
        &:hover,
        &:focus {
          color: $color;
        }
        &.is-active {
          color: $color;
          &::after {
            background-color: var(--au-color);
          }
        }
      }
    }
  }
}
.auPageCenter {
  height: 100%;
}
</style>
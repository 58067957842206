<template>
  <div class="sidePage">
    <div class="box">
      <div class="item">
        <img src="@/assets/img/msg.png" />
        <div class="title">在线客服</div>
      </div>
      <div class="line"></div>
      <div class="item">
        <img src="@/assets/img/qq.png" />
        <div class="title">QQ咨询</div>
      </div>
    </div>
    <!-- 回到顶部 -->
    <el-backtop class="auBacktop" right="0" bottom="20">
      <a href="javascript:;" class="toTop">
        <div class="default">
          <i class="el-icon-arrow-up"></i>
        </div>
      </a>
    </el-backtop>
  </div>
</template>

<script>
export default {
  data() {
    return {
      links: [
        {
          title: "主页",
          active: "我的主页",
          icon: "el-icon-s-home",
        },
        {
          title: "客服",
          icon: "el-icon-headset",
          popup: {
            image: require("@/assets/ercode.png"),
          },
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.sidePage {
  position: fixed;
  right: 50px;
  bottom: 120px;
  width: 66px;
  height: 144px;
  z-index: 10;
  .box {
    position: relative;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 0 30px 0 rgba(43, 50, 74, 0.1);
    border-radius: 2px;
  }
  .line {
    width: 70%;
    height: 1px;
    margin: auto;
    overflow: hidden;
    background-color: #eaecf0;
  }
  .item {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding: 14px 0;
    cursor: pointer;
    &:hover {
      background-color: #f8faff;
    }
    img {
      margin-bottom: 4px;
      width: 20px;
    }
    .title {
      font-size: 12px;
      color: #666;
    }
  }
  .auBacktop{
    position: static;
    margin: 14px auto;
    // box-shadow: 0 0 30px 0 rgba(43, 50, 74, 0.1);
    background: rgba(0,0,0,0);
    box-shadow: none;
  }
  .toTop{
    text-align: center;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    i{
      font-weight: bold;
      font-size: 24px;
      color: #9da0ad;
    }
    p{
      font-size: 12px;
    }
  }
}
</style>
<template>
  <div>
    <!--顶部导航-->
    <au-header></au-header>
    <!--主体内容-->
    <div class="body">
      <router-view></router-view>
    </div>
    <!-- 底部 -->
    <au-footer v-if="!hideFooter"></au-footer>
    <!-- 侧边栏 -->
    <au-sidebar v-if="!hideSidebar"></au-sidebar>
  </div>
</template>

<script>
import AuFooter from "../components/auFooter.vue";
import auHeader from "../components/auHeader.vue";
import auSidebar from "../components/auSidebar.vue";
export default {
  components: { auHeader, AuFooter, auSidebar },
  data() {
    return {
      hideFooter: false,
      hideSidebar: false,
    };
  },
  watch: {
    $route(to, from) {
      this.updateMeta();
    },
  },
  methods:{
    updateMeta(){
      const to=this.$route;
      if (to.meta) {
        this.hideFooter = !!to.meta.hideFooter;
        this.hideSidebar = !!to.meta.hideSidebar;
      }
    }
  },
  created(){
    this.updateMeta()
  }
};
</script>

<style lang="scss" scoped>
.body {
  padding-top: 60px;
}
</style>